* {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
::selection {
	background: #007aff30;
}
::-moz-selection {
	background: #007aff30;
}

/* iPad */
@media screen and (min-width: 767px) and (max-width: 1260px) {
	.search-container .search-input {
		min-width: auto !important;
		width: 130px !important;
	}
	.tab {
		padding: 10px 15px !important;
	}
	.variable  {
		font-size: 12px !important;
	}
}
/* iPad Portrait */
@media only screen and (min-device-width: 767px) and (max-device-width: 1024px) and (orientation: portrait) {
	/* .code-pane {
		border: none !important;
		height: 85% !important;
	}*/
	.editor-navigation {
		grid-template-columns: 0fr 1fr 1fr 2fr !important;
	}
	.file-pane {
		position: fixed;
		left: -2000px;
		bottom: 0;
		top: 50px;
		box-shadow: 2px 6px 15px rgba(0, 0, 0, 0.25);
	}
	.result-pane {
		position: fixed;
		z-index: 200;
		height: 500px !important;
		right: 80px;
		top: 45px;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		box-shadow: -1px 5px 16px rgba(0, 0, 0, 0.27) !important;
		border: 2px solid #ccc;
	}
}
/* iPad Landscape */
@media only screen and (min-device-width: 767px) and (max-device-width: 1260px) and (orientation: landscape) {
	.editor-navigation {
		grid-template-columns: 2.2fr 1fr 2fr !important;
	}
}
/* Phone Responsiveness */
@media screen and (min-width: 120px) and (max-width: 767px) {
	.tab {
		padding: 8px 10px !important;
	}
	.account-settings-bar {
		width: 100% !important;
    bottom: 0 !important;
    right: 0;
    left: 0 !important;
    position: fixed !important;
    z-index: 10000 !important;
    background-position: 30% center !important;
    padding-left: 6% !important;
	}
	.savedstatus {
		display: none !important; /* for now */
	}
	.editor-title {
		display: none !important;
	}
	.no-files-overlay {
		width: 100% !important;
	}
	.file-pane input {
    width: 92% !important;
	}
	.file-list li.open-folder > ul > li {
		width: 96% !important;
	}
	#download-result h1 {
		opacity: 0 !important;
	}
	.modal .large-btn {
		margin: 10px 20px !important;
	}
	.search-container input {
		width: 70% !important;
		min-width: auto !important;
		margin-left: 8px !important;
	}
	.editor-navigation > div:last-child {
		width: 200px;
	}
	.editor-navigation {
		grid-template-columns: 0fr 0fr 1fr 1fr !important;
	}
	.file-pane {
		position: fixed;
		left: -2000px;
		bottom: 0;
		top: 50px;
		right: 0;
		width: 100% !important;
		overflow-x: hidden !important;
	}
	.code-pane,
	.result-pane {
		border: none !important;
	}
	.code-pane {
		border: none !important;
		height: 93vh !important;
	}
	.error-messages {
		bottom: 0;
		left: 0;
		padding: 0 !important;
		position: relative !important;
		margin: 0 !important;
	}
	.jump-error {
		padding: 0 !important;
	}
	.e-message {
		border-radius: 0 !important;
		display: block;
		border: none !important;
		padding: 10px !important;
		margin: 0 !important;
		font-size: 12px !important;
		background-image: none !important;
	}
	.e-message button {
		font-size: 13px !important;
		padding: 8px 15px !important;
	}
	.search-action-results {
		position: fixed !important;
		top: 51px !important;
		left: 0 !important;
		right: 0 !important;
		bottom: 0;
		margin-left: 0 !important;
		box-shadow: none !important;
		border-radius: 0 !important;
		width: auto !important;
		max-height: 100% !important;
	}
	.modal {
		width: 100% !important;
		padding: 0 !important;
		border-radius: 0 !important;
		position: absolute;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
		overflow: auto;
	}
	.modals-container {
		justify-content: unset !important;
		align-items: unset !important;
	}
	#create-edit-shortcut {
		padding: 20px !important;
		width: auto !important;
	}
	.ios-tabs {
		grid-template-columns: repeat(auto-fill,minmax(150px,1fr)) !important;
	}
	#create-edit-shortcut .large-btn {
		margin: 10px 0 !important;
	}
}
/* More general restrictive responsiveness */
@media screen and (min-width: 120px) and (max-width: 768px) {
	.editor-menu {
		display: none !important;
	}
	.file-pane.open-menu {
		left: 0;
	}
	.mobile-filemenu {
		display: block !important;
	}
	.editor-container {
		display: block !important;
		grid-template-columns: auto !important;
		position: static !important;
		margin-top: 50px;
	}
	/* .editor-navigation {
		grid-template-columns: 0fr 1fr 1fr 2fr !important;
	} */
}
/* General Responsiveness */
@media screen and (min-width: 120px) and (max-width: 1260px) {
	.glyphs-container {
		height: 50vh !important;
	}
	.too-many-actions {
		width: 100% !important;
	}
	.editor-navigation {
		grid-template-columns: 3fr 0.1fr 2fr;
	}
	.result-pane {
		position: fixed;
		z-index: 200;
		top: 50px;
		width: auto !important;
		right: 0 !important;
		left: 0;
		bottom: 0;
		padding-bottom: 0 !important;
		border-radius: 0;
		border: none !important;
		height: 95vh !important;
	}
	.result-text {
		border-left: none !important;
	}
	.download-or {
		display: none !important;
	}
	.download-grid > div:first-child:after {
		display: none;
	}
	.result-pane.mobiledisabled {
		display: none;
	}
	.close-search-btn {
		display: none !important;
	}
	.close-mobile-search-btn {
		display: block !important;
	}
	#run-preview {
		display: inline-block !important;
	}
	.loading-result-progress {
		right: 0 !important;
		left: 0;
		width: auto !important;
	}
	.result-details {
		display: none !important;
	}
	.editor-navigation div:nth-child(2) > .editor-btn {
		display: none !important;
	}
	.download-grid {
		display: block !important;
	}
	.download-grid > div:first-child {
		border-right: none !important; /* border-bottom: 1px solid #eee; */
	}
	.download-grid > div:last-child {
		display: none;
	}
	.code-pane #ace_editor {
		height: 100vh !important;
	}
}
/**/
html,
body {
	font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
	margin: 0;
	padding: 0;
}
hr {
	border: 0;
	height: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
a:link {
	color: #007aff;
	text-decoration: none;
}
a:visited {
	color: #007aff;
}
a:hover,
a:active,
a:focus {
	text-decoration: underline;
	color: #007aff;
	cursor: pointer !important;
}
button {
	cursor: pointer;
	outline: none;
	font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
}
.disablethis {
	pointer-events: none;
	opacity: .5;
	user-select: none;
	filter: grayscale(100%);
}
.upload-area {
	user-select: none;
	position: fixed;
	z-index: 500;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #b8daffd1;
	font-size: 25px;
	font-weight: bold;
	color: #38618e70;
	border: 5px dashed #007aff50;
}
.upload-area div {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
#download-result {
	text-align: center;
	width: 530px;
}
.details-text {
	font-size: 14px;
	color: #888;
	margin: 10px 0;
}
.modals-container {
	display: none;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
}
.modal {
	margin: 0 auto;
	display: none;
	width: 600px;
	background-color: #fff;
	border-radius: 15px;
	padding: 10px 23px;
	box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.2);
}
#import-shortcut .extra-modal-space {
	display: none;
}
.modal .btn {
	margin: 10px 0;
}
.stretch-btn {
	width: stretch;
}
.modal.dialog {
	width: 400px;
}
.modal h1 {
	display: inline-block;
}
.editor-window {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
/* inputs */
input,
textarea,
[contenteditable] {
	caret-color: #007aff;
}
.input-label {
	font-weight: 500;
	color: #555;
	font-size: 15px;
	line-height: 22px;
	margin: 15px 0;
}
.color-label {
	margin-bottom: 0;
}
input {
	appearance: none;
	padding: 10px;
	background-color: #ebebec;
	width: stretch;
	border-radius: 8px;
	border: none;
	font-size: 14px;
	margin: 10px 0;
	min-width: 200px;
	outline: none;
	transition: 0.3s background-color;
	font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
}
input:active {
	background-color: #8c99b350 !important;
}
.search-input, .ace_search_field {
	background: #ebebec url(img/search.svg) 8px center / 20px no-repeat !important;
	padding-left: 35px !important;
	margin-bottom: 5px !important;
	margin-top: 1px !important;
	width: 93%;
}
/* required field errors */
.require-error {
	background: url(img/error.svg) left center / 16px no-repeat;
	padding-left: 23px;
	color: #d74c39;
	margin-top: 10px;
	font-size: 14px;
}
/* ios tabs */
/* .ios-tabs {
	display: grid;
	grid-template-columns: repeat(auto-fill,minmax(200px,1fr));
	margin: 5px 0;
}
.ios-tabs button {
	background: none;
	text-align: center;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 14px;
	color: #007aff;
	border: 0.5px solid #007aff;
	border-right: none;
	padding: 8px 0;
	user-select: none;
	cursor: pointer;
}
.ios-tabs button.active-itab {
	background-color: #007aff;
	color: #fff;
}
.ios-tabs button:first-child {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}
.ios-tabs button:last-child {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border-right: 0.5px solid #007aff;
} */

.ios-tabs {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(10%,1fr));
	margin: 5px 0;
	background-color: #eeeeee;
	padding: 3px;
	border-radius: 10px;
	cursor: pointer;
}
.ios-tabs button {
	background: none;
	border-radius: 8px;
	border: none;
	text-align: center;
	display: inline-block;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 12px;
	color: #000;
	padding: 8px 0;
	user-select: none;
	transition: 0.3s all;
}
.ios-tabs button.active-itab {
	background-color: #fefefe;
	box-shadow: 0 3px 5px #d2d2d2;
	font-weight: 500;
}

/* navigation + nav elements */
.account-settings-bar {
	background: url(img/settings.svg) 73px center / 22px no-repeat;
	padding: 15px 0;
	padding-left: 33px;
	font-size: 16px;
	text-align: center;
	position: absolute;
	bottom: 0;
	z-index: 101;
	color: #333;
	font-weight: 500;
	background-color: #f4f3f3;
	left: 0;
	width: 267px;
	border-top: 0.5px solid #c8c7cc;
	box-shadow: -3px -3px 10px rgba(0,0,0,.1);
}
.mobile-filemenu {
	background: url(img/menu.svg) center / 27px no-repeat;
	padding: 20px;
	height: 8px;
}
.open-filemenu {
	background-image: url(img/back.svg);
	background-size: 20px;
}
.editor-navigation {
	background-color: #f6f6f8;
	border-bottom: 0.5px solid #c8c7cc;
	display: grid;
	grid-template-columns: 0.8fr 0.8fr 0.8fr;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 500;
	height: 50px;
}
.editor-navigation > div:last-child {
	text-align: right;
}
.editor-title {
	padding: 15px;
	font-weight: bold;
	display: inline-block;
	background: url(img/icon.png) left center / 30px no-repeat;
	padding-left: 37px;
	margin-left: 10px;
}
.result-details {
	color: #999;
	margin: 10px 0;
	border-right: 1px solid #eee;
	padding-right: 15px;
	display: inline-block;
}
.result-actions {
	padding: 5px 10px;
	background-color: #eee;
	color: #555;
	font-weight: 600;
	margin-right: 5px;
	border-radius: 5px;
	display: inline-block;
	font-size: 15px;
}
/* download section */
.download-or {
	color: #999;
	display: inline-block;
	padding: 10px !important;
	font-weight: bold;
	text-transform: uppercase;
	margin-left: -22px;
	margin-top: 90px;
	background: #fff;
	height: 20px;
}
/* link buttons */
.mobile-download {
	display: none;
}
.editor-btn {
	display: inline-block;
	cursor: pointer;
	font-size: 15px;
}
.editor-btn a {
	color: #007aff;
	text-decoration: none;
	padding: 15px;
	display: inline-block;
}
.editor-btn.primary-btn {
	font-weight: bold;
}
.editor-btn:hover {
	opacity: 0.8;
}
.editor-btn:active {
	opacity: 0.5;
}
.qr-btn {
	background: url(img/qr.svg) 12px center / 22px no-repeat;
	padding-left: 40px !important;
}
/* editor + result panes */
.editor-container {
	display: grid;
	grid-template-columns: 0fr 1fr 0fr;
	position: fixed;
	top: 50px;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
}
.code-pane {
	height: 97vh;
	overflow: hidden !important;
}
.file-pane,
.code-pane,
.result-pane {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}
.result-pane {
	border-left: 0.5px solid #c8c7cc;
	background-color: #e1e3e6;
	box-shadow: inset 0 0 10px rgba(150, 150, 150, 0.5);
	padding-top: 0;
	transition: 0.3s opacity;
	width: 330px;
	height: stretch;
	overflow-y: auto;
}
.result-pane.loading {
	pointer-events: none;
}
.result-text {
	font-size: 14px;
	color: #636365;
	background: #e3e4e9;
	position: sticky;
	top: 0;
	padding: 7px 10px;
	z-index: 5;
	border-left: 0.5px solid #bfc0c4;
	border-bottom: 0.5px solid #c6c7cb;
}
.result-text span {
	font-weight: 600;
	color: #1378ee;
}
.result-pane > div:nth-child(2):not(.loading-result-progress):not(.preview-error) {
	padding: 20px;
	padding-top: 0;
	overflow: scroll;
	height: calc(100vh - 85px);
}
#run-preview {
	display: none;
}
/* ace editor */
#ace_editor {
	position: relative;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100% !important;
	height: 90% !important;
	cursor: text;
}
.ace_gutter-cell.ace_error {
	background: url(img/error_x.svg) 2px 1px / 14px no-repeat !important;
}
.ace_invalid {
	background-color: #d74c39 !important;
}
.ace_active-line {
	background-color: #007aff10 !important;
}
.ace_cursor {
	color: #007aff !important;
}
/* download modal */
.generate-code-load .load {
	margin: 30px auto;
}
#qr-result {
	width: 150px;
	height: 150px;
	border: 2px solid #c8c7cc;
	border-radius: 3px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
}
#qr-result .qrresimg {
	width: 150px;
	height: 150px;
	background-size: 110%;
	background-position: center;
	image-rendering: pixelated;
}
#download-shortcut-link {
	text-decoration: none;
	text-align: center;
}
#no-file-msg {
	padding: 80px 0;
	border: 1px solid #eee;
	border-radius: 10px;
	background: #fafafa;
	margin-bottom: 20px;
}
.shortcut-filename {
	margin: 5px;
	font-size: 18px;
	font-weight: 500;
	color: #000;
}
.shortcut-filedetails {
	margin: 5px;
	font-size: 15px;
	color: #888;
	text-transform: uppercase;
}
.download-grid {
	display: grid;
	grid-template-columns: 1fr 0fr 1fr;
	text-align: center;
}
.download-grid > div {
	padding: 20px;
}
.download-grid > div:first-child {
	border-right: 1px solid #eee;
	display: flex;
	justify-content: center;
	align-items: center;
}
.btn {
	padding: 10px 20px;
	background-color: #eee;
	border-radius: 5px;
	margin: 5px;
	font-size: 16px;
	color: #007aff;
	font-weight: 500;
	cursor: pointer;
	border: none;
	text-align: center;
	transition: 0.3s all;
}
.btn:hover {
	background-color: #f5f5f5;
}
.btn:active {
	opacity: 0.7;
}
.large-btn {
	font-size: 18px;
	padding: 15px 20px;
	background: #007aff;
	color: #ffffff;
	font-weight: 600;
}
.large-btn:hover {
	background: #007aee;
}
.large-btn:active {
	box-shadow: 0 0 20px rgba(50,50,50,.3);
}
.trans-btn {
	background: none !important;
}
.cancel-btn {
	font-weight: normal;
	background: none;
	float: right;
	margin-top: 23px;
	color: #007aff;
	cursor: pointer;
	user-select: none;
}
.cancel-btn:hover {
	opacity: 0.7;
}
.cancel-btn:active {
	opacity: 0.5;
}
/* Preview overlays */
.preview-error {
	position: absolute;
	z-index: 100;
	right: 0;
	top: 0;
	bottom: 0;
	width: 329px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #f0f0f2;
}
.preview-error div, .preview-error div {
	padding: 10px;
	text-align: center;
	font-weight: 500;
	font-size: 15px;
}
.preview-error p, .preview-error p {
	margin-bottom: 25px;
	line-height: 22px;
}
.render-error > div {
	background: url(img/error_grey.svg) top center / 35px no-repeat;
	color: #a6a6aa;
	padding-top: 30px;
}
/* Code pane overlays */
.editor-error {
		width: 100%;
		height: stretch;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #f0f0f2;
		color: #ababab;
	}
	.editor-error > div {
		text-align: center;
		font-weight: 500;
	}
	.editor-error > div > h3 {
		margin: 0;
		margin-bottom: 10px;
		line-height: 22px;
	}
	.editor-error > div > p {
		margin: 5px 0;
		line-height: 22px;
		font-size: 15px;
	}
	/* File pane overlays */
	.no-files-overlay {
		position: absolute;
		z-index: 100;
		left: 0;
		top: 116px;
		bottom: 0;
		width: 300px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #fafafa;
		color: #555;
	}
	.no-files-overlay > div {
		text-align: center;
		font-weight: 500;
	}
	.no-files-overlay > div > h3 {
		margin: 0;
		margin-bottom: 10px;
		line-height: 22px;
		font-size: 18px;
	}
	.no-files-overlay > div > p {
		margin: 5px 0;
		line-height: 22px;
		font-size: 13px;
	}
/* loading indicator */
.result-pane.loading .loading-result-progress {
	display: flex;
}
.loading-result-progress {
	display: none;
	position: absolute;
	z-index: 500;
	right: 0;
	top: 45%;
	width: 330px;
}
.result-pane.loading > div {
	opacity: 0.5;
}
.loading-result-progress > div {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}
div.spinner {
  position: relative;
  width: 50px;
  height: 50px;
  display: inline-block;
}
div.spinner div {
  width: 6%;
  height: 16%;
  background: #000;
  position: absolute;
  left: 49%;
  top: 43%;
  opacity: 0;
  border-radius: 40%;
  /* box-shadow: 0 0 3px rgba(0,0,0,0.2); */
  animation: fade 1s linear infinite;
}
@-webkit-keyframes fade {
  from {opacity: 1;}
  to {opacity: 0.25;}
}

div.spinner div.bar1 {
  -webkit-transform:rotate(0deg) translate(0, -130%);
  -webkit-animation-delay: 0s;
}
div.spinner div.bar2 {
  -webkit-transform:rotate(30deg) translate(0, -130%);
  -webkit-animation-delay: -0.9167s;
}
div.spinner div.bar3 {
  -webkit-transform:rotate(60deg) translate(0, -130%);
  -webkit-animation-delay: -0.833s;
}
div.spinner div.bar4 {
  -webkit-transform:rotate(90deg) translate(0, -130%);
  -webkit-animation-delay: -0.7497s;
}
div.spinner div.bar5 {
  -webkit-transform:rotate(120deg) translate(0, -130%);
  -webkit-animation-delay: -0.667s;
}
div.spinner div.bar6 {
  -webkit-transform:rotate(150deg) translate(0, -130%);
  -webkit-animation-delay: -0.5837s;
}
div.spinner div.bar7 {
  -webkit-transform:rotate(180deg) translate(0, -130%);
  -webkit-animation-delay: -0.5s;
}
div.spinner div.bar8 {
  -webkit-transform:rotate(210deg) translate(0, -130%);
  -webkit-animation-delay: -0.4167s;
}
div.spinner div.bar9 {
  -webkit-transform:rotate(240deg) translate(0, -130%);
  -webkit-animation-delay: -0.333s;
}
div.spinner div.bar10 {
  -webkit-transform:rotate(270deg) translate(0, -130%);
  -webkit-animation-delay: -0.2497s;
}
div.spinner div.bar11 {
  -webkit-transform:rotate(300deg) translate(0, -130%);
  -webkit-animation-delay: -0.167s;
}
div.spinner div.bar12 {
  -webkit-transform:rotate(330deg) translate(0, -130%);
  -webkit-animation-delay: -0.0833s;
}

/*Error color*/
.error {
	/*Don't remove*/
	background: #ffdcdc !important;
	/*Don't remove*/
}
.error-messages {
	position: absolute;
	z-index: 110;
	right: 0;
	margin: 15px;
}
.e-message {
	background: url(img/error_w.svg) 10px center / 20px no-repeat;
	background-color: #d74c39;
	border: 2px solid #d74c3950;
	padding: 13px 20px;
	padding-left: 40px;
	border-radius: 5px;
	color: #fff;
	font-size: 13px;
	margin-bottom: 13px;
	box-shadow: 0 0 5px rgba(150, 150, 150, 0.5);
	display: grid;
	grid-template-columns: 1fr 0fr;
	max-width: 400px;
	font-weight: 500;
}
.e-message .jump-error {
	padding: 0 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.e-message button {
	background-color: #fff;
	border: none;
	font-size: 14px;
	color: #d74c39;
	border-radius: 5px;
	font-weight: bold;
	padding: 8px 20px;
	transition: 0.3s opacity;
}
.e-message button:active {
	opacity: 0.7;
}
/* Tabs */
.file-tabs {
	border-bottom: 0.5px solid #adacac;
	background: #ccc;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(10%,1fr));
}
.file-tabs .tab:not(:last-child) {
	border-right: 0.5px solid #adacac;
}
.tab {
	padding: 5px 10px;
	color: #3d3c3d;
	transition: 0.1s all;
	cursor: pointer;
	display: inline-grid;
	grid-template-columns: 0fr 1fr;
	margin-bottom: -3px;
	overflow: hidden;
}
.tab:hover:not(.active-tab) {
	background: #a7a8a750;
}
.tab.active-tab {
	background: #e3e4e3;
	color: #1d1d1c;
}
.tab-close,
.tab-label {
	display: inline-block;
}
.tab-close {
	font-size: 20px;
	line-height: 12px;
	border-radius: 3px;
	color: #454445;
	height: 15px;
	width: 15px;
	cursor: pointer;
	user-select: none;
	padding: 3px;
	text-align: center;
	transition: 0.1s all;
}
.tab-close:active {
	opacity: 0.7;
}
.tab.active-tab .tab-close {
	opacity: 1;
}
.tab.active-tab .tab-close:hover {
	background: #ccc;
}
.tab .tab-close:hover {
	background: #aaa;
}
.tab .tab-close {
	opacity: .5;
}
.tab:hover .tab-close {
	opacity: 1;
}
.tab-label {
	font-size: 13px;
	line-height: 20px;
	padding-left: 5px;
	user-select: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}
/* Menus */
.savedstatus {
	display: inline-block;
	transition: 0.2s all;
	font-size: 14px;
	margin-right: 20px;
	color: #555;
}
.editor-menu {
	display: inline-block;
	color: #007aff;
	cursor: pointer;
	transition: 0.2s all;
}
.editor-menu ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	padding: 16px;
	display: inline-block;
	font-size: 15px;
}
.editor-menu > ul:first-child:hover {
	background: #007aff;
	color: #fff;
}
.editor-menu ul ul {
	margin-left: -15px;
	position: absolute;
	padding: 10px 0;
	padding-left: 1px;
	background-color: #fff;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	margin-top: 16px;
	box-shadow: 0 3px 10px rgba(100, 100, 100, 0.3);
	min-width: 230px;
	display: none;
	border: 0.5px solid #c8c7cc;
}
.editor-menu ul:hover ul {
	display: block;
}
.editor-menu ul ul li a {
	display: block;
	padding: 6px 15px;
	font-size: 14px;
	text-decoration: none;
	color: #1d1d1c;
	font-weight: 500;
	user-select: none;
}
.editor-menu li a:hover span {
}
.editor-menu li a span {
	float: right;
	color: #748ca5;
	font-size: 14px;
	line-height: 15px;
	text-align: right;
	padding: 3px 4px;
	border-radius: 4px;
	letter-spacing: 1px;
	margin-top: -3px;
}
.editor-menu ul ul li a:hover {
	background: #007aff;
	color: #fff;
}
.editor-menu ul ul li a:hover span {
	float: right;
	color: #ffffff;
	font-size: 14px;
	line-height: 15px;
	text-align: right;
	padding: 3px 4px;
	border-radius: 4px;
	letter-spacing: 1px;
	margin-top: -3px;
}
.menu-div {
	border-top: 1px solid #5a798b30;
	margin: 10px 15px;
}
/* Variables */
.variables-container {
	height: 40px;
	overflow: hidden;
	border-bottom: 1px solid #bbbfc4;
}
.variables {
	background: #e3e4e3;
	overflow-x: scroll;
	white-space: nowrap;
	padding: 3px 5px;
}
.code-variables, .global-variables {
	display: inline-block;
}
.global-variables {
	padding-right: 10px;
	margin-right: 10px;
	border-right: 0.5px solid #ccc;
}
.variable {
	display: inline-block;
	margin: 5px 3px;
	font-size: 11px;
	padding: 4px 12px;
	padding-bottom: 6px;
	font-weight: 500;
	border: 0.5px solid #b0bbd1;
	border-radius: 20px;
	background: linear-gradient(#d7e5f5,#cdd9ef);
	box-shadow: 0 1px 1px rgba(50,50,50,0.1);
	color: #233145;
	cursor: pointer;
	transition: 0.2s all;
	user-select: none;
}
.variable.mv {
	background: linear-gradient(#d7d6f4,#dbceef);
	color: #392c4f;
	border-color: #c4bcd2;
}
.variable:hover {
	box-shadow: 0 1px 0.5px rgba(50,50,50,.3);
}
.variable > div:before {
	content: ' ';
	display: inline-block;
  padding: 8px 7px;
  position: relative;
  margin-bottom: -4px;
	margin-top: -2px;
  margin-right: 6px;
	background: url(img/var-sheet.png) 0px / cover no-repeat;
}
.global-variables .variable {
	background: linear-gradient(to bottom, #dbf5da, #d1ebd2);
	border-color: #b2ceb7;
	color: #224425;
}
.clipboard-v > div:before {
	background-position: 0px 0px;
}
.date-v > div:before {
	background-position: -15px 0px;
}
.input-v > div:before {
	background-position: -28.7px 0px;
}
.file-v > div:before {
	background-position: -44px 0px;
}
.calc-v > div:before {
	background-position: -58px 0px;
}
.gear-v > div:before {
	background-position: -72px 0px;
}
.url-v > div:before {
	background-position: -87px 0px;
}
.gif-v > div:before {
	background-position: -101px 0px;
}
.photos-v > div:before {
	background-position: -116px 0px;
}
.mv > div:before {
	background: url(img/magic.svg) center / cover no-repeat;
	margin-right: 3px;
}
/* Find and replace */
.ace_search {
	background-color: #fff !important;
	border-radius: 15px;
	padding: 10px !important;
	padding-top: 5px !important;
	border: none !important;
	border-top: none !important;
	box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.2);
}
.ace_search_field {
	border: none !important;
	padding: 5px 10px !important;
	padding-left: 35px !important;
	margin: 8px 0 !important;
	border-radius: 8px !important;
}
.ace_searchbtn, .ace_button {
	border-radius: 5px !important;
	font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
	border: 2px solid #eee !important;
	background-color: #eee !important;
	color: #007aff !important;
	margin: 0 3px !important;
	font-size: 14px !important;
	transition: 0.2s all;
}
.ace_searchbtn:hover, .ace_button:hover {
	background-color: #f5f5f5 !important;
}
.ace_searchbtn:active, .ace_button:active {
	opacity: .7;
}
.ace_button.checked {
	border: 2px solid #007aff50 !important;
	font-weight: 500;
}
.ace_searchbtn {
	padding: 1px 12px !important;
}
.ace_button {
	padding: 5px 13px !important;
}
.ace_search_options {
	margin-top: 15px;
}
.ace_search_counter {
	padding: 2px 10px !important;
	font-size: 15px;
}
[action="toggleReplace"] {
	margin-top: -5px !important;
}
.ace_searchbtn_close {
	opacity: .7;
	user-select: none;
	margin-top: 10px;
	margin-right: 10px;
	padding: 2px !important;
	background: url(img/close.svg) center / contain no-repeat !important;
}
.ace_searchbtn_close:hover {
	opacity: 1;
}
.ace_searchbtn_close:active {
	opacity: .5;
}
.ace_nomatch {
	outline: none !important;
}
.ace_nomatch .ace_search_field {
	background-color: #d74c3930 !important;
}
/* Dark Mode */
.dark .editor-navigation {
	background: #323234;
	border-color: #444;
}
.dark .editor-title {
	color: #fff;
}
.dark .result-details {
	border-color: #444;
}
.dark .result-actions {
	background-color: #78777d;
	color: #eee;
}
.dark .editor-btn a, .dark .editor-menu  {
	color: #367bf4;
}
.dark .files-header, .dark .file-pane, .dark .variables, .dark .result-text, .dark .variables-container, .dark .result-pane, .dark .preview-error,.dark .editor-error, .dark .files-error {
	background-color: #1c1c1e;
	border-color: #444;
	color: #ccc;
}
.dark .savedstatus {
	color: #eee;
}
.dark .global-variables {
	border-color: #444;
}
.dark .result-pane {
	box-shadow: 0 0 0 black;
	border-color: #444;
}
.dark .file-btn {
	background-color: #333;
}
.dark input {
	color: #fff;
}
.dark .search-input, .dark .ace_search_field {
	background: #444 url(img/search.svg) 8px center / 20px no-repeat !important;
}
.dark .large-btn {
	background-color: #f8bc3d;
	color: #000;
}
.dark .modal, .dark .download-or, .dark .editor-menu ul ul, .dark .account-settings-bar {
	background-color: #323234;
	color: #fff;
	border-color: #444;
}
.dark .download-grid > div:first-child {
	border-color: #444;
}
.dark .shortcut-filename {
	color: #fff;
}
.dark .editor-menu ul ul li a {
	color: #fff;
}
.dark .editor-menu ul ul li a span {
	color: #fff;
}
.dark .account-settings-bar {
	background-image: url(img/settings_w.svg) !important;
}
.dark .mv > div:before {
	background-image: url(img/magic_w.svg) !important;
}
.dark .variable {
	border-radius: 5px;
	background: #94c3f224 !important;
	color: #367bf4;
	font-weight: 500;
	border: none;
}
