/* File pane */
.file-pane {
	width: 300px;
	background-color: #fff;
	border-right: 0.5px solid #c7c7cc;
	transition: 0.1s left ease-out;
	z-index: 100;
	padding-bottom: 20px;
	overflow-x: hidden;
	overflow-y: auto;
	height: stretch;
}
.file-pane input {
	width: 90%;
	margin-left: 15px;
}
.file-pane h2 {
	margin: 20px 15px;
}
/* Files Navigation */
.files-header {
	position: sticky;
	top: 0;
	background-color: #fafafa;
	height: 55px;
	border-bottom: 1px solid #ddd;
	margin-bottom: 10px;
	padding: 15px 0;
	box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.15);
}
.file-btns {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	padding: 0 10px;
}
.file-btn {
	font-size: 15px;
	padding: 13px 0;
	width: 100%;
	width: stretch;
	padding-left: 30px;
	font-weight: normal;
	outline: none;
}
.upload-btn {
	background: #eee url(img/upload.png) center / 25px no-repeat;
}
.large-upload-btn {
	background: #eee url(img/upload.png) 22% center / 25px no-repeat;
	padding-left: 10%;
}
.new-btn {
	background: #eee url(img/new_file.png) center / 25px no-repeat;
}
.newf-btn {
	background: #eee url(img/new_folder.png) center / 25px no-repeat;
}
/* File list */
.file-list li {
	padding: 8px 10px;
	padding-left: 25px;
	cursor: pointer;
	user-select: none;
	transition: 0.3s background-color;
}
.file-list li:hover:not(.open-folder) {
	background-color: #e8f6fe !important;
}
.file-list li:active:not(.open-folder) {
	background-color: #daeffc !important;
}
.file-list li.open-folder > ul > li {
	width: 94%;
}
.file-list ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}
.file-list ul ul {
	border-left: 1px solid #ddd;
	margin: 5px 10px;
}
.list-item-file > div,
.list-item-folder > div {
	padding-left: 27px;
	font-size: 14px;
}
.item-name {
	width: 70%;
	height: 18px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	margin-top: 3px;
}
.item-name .load {
	display: none;
}
.file-list ul li.open-folder ul li > div > .item-name {
	width: 60%;
}
.file-list ul li.open-folder ul li.open-folder ul > li > div > .item-name {
	width: 50%;
}
/* File items */
.list-item-file > div {
	background: url(img/file.png) 2px center / 18px no-repeat;
}
.file-list li.list-item-file.active {
	background-color: #007aff20;
	font-weight: 500;
}
.file-list li.list-item-file.active:hover {
	background-color: #007aff10 !important;
}
.file-list li.list-item-file.active > div {
	background-image: url(img/file_active.png);
}
/* uploading state */
.list-item-file.uploading {
	background: none;
	pointer-events: none;
	user-select: none;
	color: #777;
}
.list-item-file.uploading .action-btns {
	opacity: 0.5;
}
.list-item-file.uploading > div {
	background: none;
}
.list-item-file.uploading .item-name .load {
	display: inline-block;
	position: absolute;
	margin-left: -25px;
	margin-top: 2px;
	width: 10px;
	height: 10px;
}
/* Folder items */
.list-item-folder > div {
	background: url(img/folder.png) left center / 20px no-repeat;
}
.file-list li.list-item-folder > ul {
	display: none;
	transform-origin: top center;
	transition: 0.1s left ease-out;
	transform: scale(1, 0);
}
.file-list li.list-item-folder {
	background: url(img/right.svg) 10px 15px / 10px no-repeat;
}
.file-list li.list-item-folder.open-folder {
	background: url(img/down.svg) 10px 15px / 10px no-repeat;
	padding-bottom: 0;
}
.file-list li.list-item-folder.open-folder > ul {
	display: block;
	animation: scaleIn 0.1s;
	transform: scale(1, 1);
}
.list-item-folder.open-folder > div {
	font-weight: 500;
}
@keyframes scaleIn {
	from {
		transform: scale(1, 0);
	}
	to {
		transform: scale(1, 1);
	}
}
/* Rename and delete btns */
.action-btns {
	float: right;
	margin-top: -3px;
}
.file-list ul ul ul .action-btns {
	margin-right: 3px;
}
.edit-btn,
.delete-btn {
	background: url() center / 20px no-repeat;
	padding: 15px;
	float: right;
	border-radius: 3px;
	cursor: pointer;
}
.action-btns div:active {
	background-color: #8c99b3;
}
.edit-btn {
	background-image: url(img/edit.svg);
}
.edit-btn:active {
	background-image: url(img/edit_w.svg);
}
.delete-btn {
	background-image: url(img/delete.svg);
	margin-left: 3px;
}
.delete-btn:active {
	background-image: url(img/delete_w.svg);
}
